<div class="main-content">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header bg-gray" >
                    <div class="row">
                        <div class="col-12">
                            <span class="letra-cabecera">Mis carpetas</span>
                        </div>
                    </div>
                
                        <div class="row mt-4">

                            <div class="col-12 text-right">
                               
                            </div>

                            <div class="col-12 text-right">
                                <button  class="btn btn-success" routerLink="/admin/repositorios.list"><i class="fas fa-arrow-left mr-2"> </i>IR ATRAS</button>&nbsp;
                                <button class="btn btn-primary" (click)="openCreate()" ><i class="fas fa-plus mr-1" > </i> NUEVO</button>&nbsp;
                            </div>
                        </div>
                </div>



                <div class="row m-3 text-center" >

                    <div class="col-3" *ngFor="let data of carpeta">
                        <i class="fas fa-folder" style="padding-right: 5px; color: #E29E18; font-size: 80px; cursor: pointer;"></i>
                        <p>Nombre: {{data.nota}}</p>
                        <p>Creado: {{data.created | date: 'dd/MM/yyyy'}}</p>
                        <button  class="cursor btn btn-danger  mr-2" style="font-size: 15px;" title="Eliminar actividad" (click)="openDelete(data)"   > <i class="fas fa-trash-alt"> </i>  </button> 
                        <button  class="cursor btn btn-primary  mr-2" style="font-size: 15px;" title="Eliminar actividad" (click)="openRecursos(data)"   > <i class="fas fa-eye"> </i>  </button> 
                    </div>

                   
                </div>

                <div class="p-2 text-center">
                    <button  class="btn btn-secondary" (click)="cambiarPagina(-12)"> <i class="fas fa-arrow-left mr-1"> </i> Anteriores</button>
                    &nbsp;
                    <button  class="btn btn-success" (click)="cambiarPagina(12)">Siguientes  <i class="fas fa-arrow-right mr-1"> </i> </button> 
                </div>


              






            </div>
        </div>
    </div>
</div>
