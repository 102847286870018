<div class="main-content">
    <div class="">
        <div class="">
            <div class="card">
                <div class="card-header bg-gray" >
                    <div class="row">
                        <div class="col-12">
                            <span class="letra-cabecera">Listado de Usuarios APP</span>
                        </div>
                    </div>
                
                        <div class="row mt-4">

                            <div class="col-6 text-right">
                                <mat-form-field class="w-80" appearance="outline">
                                    <mat-label>Buscar por DNI:</mat-label>
                                    <input name="proyecto" autocomplete="off" matInput (keyup)="buscar(txtTermino.value)" #txtTermino >
                                </mat-form-field>
                            </div>

                            <div class="col-6 text-right">
                                <mat-form-field class="w-80" appearance="outline">
                                    <mat-label>Buscar por Nombre:</mat-label>
                                    <input name="proyecto" autocomplete="off" matInput (keyup)="buscar2(txtTermino2.value)" #txtTermino2>
                                </mat-form-field>
                            </div>

                           
                        </div>

                        <div class="col-12 text-right">
                            <button class="btn btn-primary" (click)="openCreate()" ><i class="fas fa-plus mr-1"> </i> NUEVO</button>&nbsp;
                            <button  class="btn btn-success" (click)="export()"><i class="fas fa-file-excel mr-2"> </i>EXPORTAR</button>&nbsp;
                        </div>


                </div>



                <div class="card-body">


                    <div class="table-responsive">
                        <br>
                        <table class="table table-bordered">
                            <thead style="background-color: #007BFF; color: #ffff; font-weight: lighter;">
                                <tr>
                                    <th>NOMBRE</th>
                                    <th>APELLIDOS</th>
                                    <th>Nº DOCUMENTO</th>
                                    <!-- <th>Correo</th> -->
                                    <th>AREA</th>
                                    <th>CELULAR</th>
                                    <th class="text-nowrap text-center">ACCIONES</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of cliente">

                                    <td>{{data.nombre}}</td>
                                    <td>{{data.apellidos}}</td>
                                    <td>{{data.dni}}</td>
                                    <!-- <td>{{data.email}}</td> -->
                                    <td>{{data.area_id}}</td>
                                    <td>{{data.celular}}</td>
                                    <td class="text-nowrap text-center">
                                        <button  class="cursor btn btn-secondary mr-2"  (click)="imprimirQR(data)" > <i class="fas fa-qrcode"> </i>  </button> 
                                        <button  class="cursor btn btn-warning mr-2" (click)="openEdit(data)" > <i class="fa fa-edit"> </i>  </button> 
                                        <button  class="cursor btn btn-danger mr-2" (click)="openDelete(data)"   > <i class="fas fa-trash-alt"> </i>  </button> 
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>

                <button  class="btn btn-secondary" (click)="cambiarPagina(-5)"> <i class="fas fa-arrow-left mr-1"> </i> Anteriores</button>
                    &nbsp;
                    <button  class="btn btn-success" (click)="cambiarPagina(5)">Siguientes  <i class="fas fa-arrow-right mr-1"> </i> </button> 

                </div>


                <div #map id="map"  style="height: 70%;"></div>

            </div>

    


        </div>
    </div>
</div>